import { useKeycloak } from '@react-keycloak/web';
import React, { FC, useEffect } from 'react';
import { useAppDispatch } from '../../state/hooks';
import { useSelector } from 'react-redux';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { DemoCredential, demoCredentialSelector, getDemoCredentials } from '../../state/slices/democredential';
import { OCard } from '../atoms';
import { formatDateForDisplay } from '../../state';

export const ShowCompany: FC = () => {
    const dispatch = useAppDispatch();
    const {keycloak, initialized} = useKeycloak();
    const demoCredentials = useSelector(demoCredentialSelector);
    let navigate = useNavigate();
    console.log('QQQ keycloak.token: ', keycloak.token);
    useEffect(() => {
        dispatch(getDemoCredentials({jwtToken: keycloak.token!}));
    }, [keycloak.token]);

    function issuePidCredentialVcJwt() {
        navigate('/issue/personal?id=pid_jwt_vc_json');
    }

    function issuePidCredentialSdJwt() {
        navigate('/issue/personal?id=pid_vc%2Bsd-jwt');
    }

    function formatBoolean(value: boolean | undefined | null) {
        if ((value === undefined) || (value === null)) {
            return '';
        }
        return value ? 'Yes' : 'No';
    }

    function label(label: string) {
        return <Typography sx={{fontSize: '12px', lineHeight: 1, color: 'rgba(28, 28, 28, 0.40)'}}>{label}</Typography>;
    }

    function value(value: string) {
        return <Typography sx={{fontSize: '14px', lineHeight: 1, color: '#1C1C1C'}}>{value}</Typography>;
    }

    function labelAndValue(sLabel: string, sValue: any) {
        return <><Grid item xs={4}>{label(sLabel)} </Grid><Grid item xs={8}>{value(sValue)}</Grid></>;
    }

    function getPersonalDetailsCard(demoCredential: DemoCredential) {

        let credential = JSON.parse(demoCredential.credentialJson);

        return <OCard title="Personal details" sx={{height: '100%'}}>
            <Grid container spacing={2}>
                {labelAndValue('Family name:', credential?.family_name)}
                {labelAndValue('Given name:', credential?.given_name)}
                {labelAndValue('Birth date:', formatDateForDisplay(credential?.birth_date))}
                {labelAndValue('Family name birth: ', credential?.family_name_birth)}
                {labelAndValue('Given name birth: ', credential?.given_name_birth)}
                {labelAndValue('Gender: ', credential?.gender)}
            </Grid>
        </OCard>;
    }

    function getBirthCard(demoCredential: DemoCredential) {
        let credential = JSON.parse(demoCredential.credentialJson);
        return <OCard title="Birth details" sx={{height: '100%'}}>
            <Grid container spacing={2}>
                {labelAndValue('Place of birth: ', credential?.birth_place)}
                {labelAndValue('Country of birth: ', credential?.birth_country)}
                {labelAndValue('State of birth: ', credential?.birth_state)}
                {labelAndValue('City of birth: ', credential?.birth_city)}
            </Grid>
        </OCard>;
    }

    function getResidentialCard(demoCredential: DemoCredential) {
        let credential = JSON.parse(demoCredential.credentialJson);
        return <OCard title="Residential details" sx={{height: '100%'}}>
            <Grid container spacing={2}>
                {labelAndValue('Resident address: ', credential?.resident_address)}
                {labelAndValue('Resident country: ', credential?.resident_country)}
                {labelAndValue('Resident state: ', credential?.resident_state)}
                {labelAndValue('Resident city: ', credential?.resident_city)}
                {labelAndValue('Resident postal code: ', credential?.resident_postal_code)}
                {labelAndValue('Resident street: ', credential?.resident_street)}
                {labelAndValue('Resident house number: ', credential?.resident_house_number)}
            </Grid>
        </OCard>;
    }

    function getIdDetailsCard(demoCredential: DemoCredential) {
        let credential = JSON.parse(demoCredential.credentialJson);
        return <OCard title="ID details" sx={{height: '100%'}}>
            <Grid container spacing={2}>
                {labelAndValue('Gender: ', credential?.gender)}
                {labelAndValue('Nationality: ', credential?.nationality)}
                {labelAndValue('Document number: ', credential?.document_number)}
                {labelAndValue('Issuing country: ', credential?.issuing_country)}
                {labelAndValue('Issuing jurisdiction: ', credential?.issuing_jurisdiction)}
                {labelAndValue('Age over 18: ', formatBoolean(credential?.age_over_18))}
                {labelAndValue('Age over 21: ', formatBoolean(credential?.age_over_21))}
                {labelAndValue('Age in years: ', credential?.age_in_years)}
                {labelAndValue('Age birth year: ', credential?.age_birth_year)}
            </Grid>
        </OCard>;
    }


    return (
        <div>
            <Grid container spacing={2} flexDirection={'row'}>
                {demoCredentials.list.map((demoCredential, index) => (
                    <Box>
                        <Typography sx={{fontSize: '64px', fontFamily: 'DM Serif Display', lineHeight: '78px', color: '#000000'}}>Your Personal ID</Typography>
                        <Typography sx={{fontSize: '14px', lineHeight: '20px', color: '#000000'}}>Securely access your personal identification information with ease.</Typography>
                        <Typography sx={{fontSize: '14px', lineHeight: '20px', color: '#000000'}}>Click on “Add Personal ID to wallet” to easily add your Digital Pasport to your wallet.</Typography>
                        <Grid container spacing={2} flexDirection={'row'}>
                            <Button variant='contained' sx={{marginTop: '28px', marginBottom: '28px', marginLeft: '16px', marginRight: '10px', textTransform: 'unset'}} onClick={issuePidCredentialVcJwt}>Add Personal ID VC-JWT to wallet</Button>
                            <Button variant='contained' sx={{marginTop: '28px', marginBottom: '28px', textTransform: 'unset'}} onClick={issuePidCredentialSdJwt}>Add Personal ID SD-JWT to wallet</Button>
                        </Grid>
                        <Grid container spacing={2} flexDirection={'row'}>
                            <Grid item xs={6} key={index}>
                                {getPersonalDetailsCard(demoCredential)}
                            </Grid>
                            <Grid item xs={6} key={index}>
                                {getBirthCard(demoCredential)}
                            </Grid>
                            <Grid item xs={6} key={index}>
                                {getResidentialCard(demoCredential)}
                            </Grid>
                            <Grid item xs={6} key={index}>
                                {getIdDetailsCard(demoCredential)}
                            </Grid>
                        </Grid>
                    </Box>
                ))}
            </Grid>
        </div>
    )
        ;
};
